@charset "UTF-8";

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote, th, td {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto Flex", sans-serif;
  background-color: rgb(199, 199, 199);
  color: rgb(48, 48, 48);
  display: block;
  width: 100%;
  margin: 0 auto;
  transition: color 600ms ease-in-out;
  transition: background-color 600ms ease-in-out;
}

/* FONT */

h1 {
  font-size: calc(0.7vw);
  font-weight: 400;
}


@media (max-width: 600px) {
  h1 {
    font-size: 10px;
  }
}


/* LINK */

li {
  list-style-type: none;
  flex-grow: 1;
  min-width: 20%;
}

a {
  color: rgb(134, 134, 134);

}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

.home{
  width: 100%;
  margin: 0 auto;
}

.info{
  width: 98%;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 70vh;
}

.project{
  width: 98%;
  margin: 0 auto;
}

.exp{
  width: 100%;
  margin: 22vh 0 5vh 0;
}

.highlight{
  color: #000000;
}

.footer{
  width: 100%;
  margin: 22vh 0 5vh 0;
}

li:last-child {
  // There's no science in using "10" here. In all my testing, this delivered the best results.
  flex-grow: 10;
  max-width: 32%;
  min-width: 32%;
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
}

img {
  border-radius: 10px;
  width: 338px;
  height: 100%;
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.link{
  margin-top: 10px;
}